import { Alert } from "@mui/material";
import React, { useEffect, useState } from "react";

export const Notification = ({ variant, message, setNotification}) => {
  // the alert is displayed by default
  const [alert, setAlert] = useState(true);

  useEffect(() => {
    console.log(message, variant, alert);
    // when the component is mounted, the alert is displayed for 3 seconds
    setTimeout(() => {
      setAlert(false);
      setNotification('');
    }, 3000);
  }, []);

  return alert && <Alert severity={variant}>{message}</Alert>;
};
