import { Typography } from "@mui/material";
import * as React from "react";
import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

export const HomePage = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
        textAlign: "center",
        margin: "30px",
        alignItems: "center",
      }}
    >
      <Typography gutterBottom variant="h2">
        Exciting things are coming soon!
      </Typography>
      <Typography variant="h5">
        We're a small company working on bringing our big ideas to life - we
        want to bring a voice to the more conscious consumer who is willing to
        put their money where there values lie.
      </Typography>
      <Button
        sx={{ width: "200px", marginTop: "30px" }}
        onClick={() => {
          navigate("/about");
        }}
        variant="contained"
      >
        Find out more
      </Button>
    </Box>
  );
};
