import "./App.css";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import { TopBar } from "./components/TopBar/TopBar";
import { HomePage } from "./pages/HomePage/HomePage";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { AboutPage } from "./pages/AboutPage/AboutPage";
import { ContactUsPage } from "./pages/ContactUs/ContactUs";
import { WhatYouValue } from "./pages/WhatYouValue/WhatYouValue";

export const App = () => {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#7478A9",
        dark: "#152530",
        light: "#E9F1E8",
      },
      secondary: {
        main: "#67AACD",
        dark: "#7478A9",
        light: "#BAA58F",
      },
    },
    typography: {
      fontFamily: ["Questrial", "cursive"].join(","),
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <TopBar />
        <Routes>
          <Route element={<HomePage />} path="/"></Route>
        </Routes>
        <Routes>
          <Route element={<AboutPage />} path="/about"></Route>
        </Routes>
        <Routes>
          <Route element={<ContactUsPage />} path="/contact-us"></Route>
        </Routes>
        <Routes>
          <Route element={<WhatYouValue />} path="/values"></Route>
        </Routes>
    </ThemeProvider>
  );
};
