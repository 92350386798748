import { Chip, Typography } from "@mui/material";
import * as React from "react";
import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { Stack } from "@mui/system";

export const WhatYouValue = () => {
  const [values, setValues] = useState([]);
  useEffect(() => {
    getValues();
  }, []);

  function getValues() {
    fetch("http://localhost:3001")
      .then((response) => {
        // console.log(response.text())
        return response.text();
      })
      .then((data) => {
        setValues(JSON.parse(data));
      });
  }
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        margin: "30px",
        alignItems: "center",
      }}
    >
      <Typography gutterBottom variant="h4">
        Help us understand what values matter to you.
      </Typography>
      <Stack
        sx={{ flexWrap: "wrap", width: "80%", justifyContent: "center" }}
        direction="row"
        spacing={0}
      >
        {values.map((value) => {
          if (value.type === "value" || value.type === "both")
            return (
              <Chip
                sx={{ margin: "5px" }}
                color="secondary"
                label={value.name}
              />
            );
        })}
      </Stack>
      <Typography gutterBottom variant="h4">
        Tell us what you need.
      </Typography>
      <Stack
        sx={{ flexWrap: "wrap", width: "80%", justifyContent: "center" }}
        direction="row"
        spacing={0}
      >
        {values.map((value) => {
          if (value.type === "need" || value.type === "both")
            return (
              <Chip
                sx={{ margin: "5px" }}
                color="secondary"
                label={value.name}
              />
            );
        })}
      </Stack>
      <Typography gutterBottom variant="h4">
        Is there something we missed?
      </Typography>
    </Box>
  );
};
