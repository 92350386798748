import { Typography } from "@mui/material";
import * as React from "react";
import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

export const AboutPage = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        margin: "30px",
        alignItems: "center",
      }}
    >
      <Typography gutterBottom variant="h4">
        What we're about
      </Typography>
      <Typography>
        In a time where transparency matters we want to share experiences and
        promote businesses and services that are truly deserving. We bring a
        voice to the more conscious consumer who wants to put their money where
        their values lie. Ever wondered if a supplier, brand or business really
        support your community needs or cause? We bet someone somewhere has the
        inside scoop and wants to tell you!
      </Typography>
    </Box>
  );
};
