import * as React from "react";
import { Box, Button, Typography, TextField, MenuItem } from "@mui/material";
import emailjs from "emailjs-com";
import { useState } from "react";
import { Notification } from "../../components/Alerts/Notification";

export const ContactUsPage = () => {
  const categories = [
    "Business Owner",
    "Review Query",
    "Account Support",
    "Other",
  ];

  const [state, setState] = useState({
    name: "",
    email: "",
    subject: "",
    description: "",
  });
  const [notification, setNotification] = useState("");
  const [category, setCategory] = useState("");

  const handleChange = (e) => {
    const { id, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    emailjs
      .send(
        "service_ivb6mqp",
        "template_zpwrfmh",
        {
          from_name: state.name,
          message: state.description,
          from_email: state.email,
          subject: state.subject,
          category: category,
        },
        "gSVT478vZ47Tfcm0t"
      )
      .then((result) => {
        if (result.status == 200) {
          setNotification("success");
        } else setNotification("error");
      });
    // e.target.reset();
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
        textAlign: "center",
        margin: "30px",
        alignItems: "center",
      }}
    >
      <Typography variant="h5" gutterBottom>
        Got a question or have some feedback? We'd love to hear from you! Please
        complete the short form below with your enquiry and our team will aim to
        get back to you within 24 hours.
      </Typography>
      <Box sx={{ width: "400px" }}>
        <TextField
          required
          id="name"
          label="Name"
          fullWidth
          margin="normal"
          onChange={handleChange}
          value={state.name}
        />
        <TextField
          required
          id="email"
          label="Email Address"
          fullWidth
          margin="normal"
          onChange={handleChange}
          value={state.email}
        />
        <TextField
          id="subject"
          label="Subject"
          fullWidth
          margin="normal"
          onChange={handleChange}
          value={state.subject}
        />
        <TextField
          id="category"
          select
          required
          label="Reason for contacting"
          onChange={handleCategoryChange}
          value={category}
          fullWidth
          margin="normal"
        >
          {categories.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          required
          id="description"
          label="Description"
          fullWidth
          margin="normal"
          onChange={handleChange}
          value={state.description}
        />
        <Button
          onClick={handleOnSubmit}
          sx={{ marginTop: "15px", marginBottom: '30px'}}
          variant="contained"
          fullWidth
        >
          Submit
        </Button>
        {notification !== "" && (
          <Notification
            setNotification={setNotification}
            variant={notification}
            message={
              notification == "success"
                ? "Thanks for getting in touch!"
                : "Oops something went wrong - please try again."
            }
          />
        )}
      </Box>
    </Box>
  );
};
