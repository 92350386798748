import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

export const TopBar = () => {
  const navigate = useNavigate();

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <Box sx={{ flexGrow: 1 }}>
            <img
              src="white_logo_transparent_background.png"
              alt="logo"
              height="75px"
            />
          </Box>
          <Box>
            <Button
              onClick={() => {
                navigate("/");
              }}
              color="inherit"
            >
              Home
            </Button>
            <Button
              color="inherit"
              onClick={() => {
                navigate("/about");
              }}
            >
              About
            </Button>
            <Button
              color="inherit"
              onClick={() => {
                navigate("/contact-us");
              }}
            >
              Contact Us
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};
